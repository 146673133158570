@font-face
  font-family: 'Neue Haas Grotesk'
  src: url('/fonts/NHG-75Bd.woff2') format('woff2'), url('/fonts/NHG-75Bd.woff') format('woff')
  font-weight: 800
  font-style: normal

@font-face
  font-family: 'Neue Haas Grotesk'
  src: url('/fonts/NHG-25Th.woff2') format('woff2'), url('/fonts/NHG-25Th.woff') format('woff')
  font-weight: 200
  font-style: normal

*
  margin: 0
  padding: 0
  font: inherit

html,
body
  height: 100%

body
  display: flex
  background-color: #282C34
  font-family: 'Neue Haas Grotesk', 'Helvetica Neue', Helvetica, sans-serif
  text-align: center
  justify-content: center
  align-items: center

h1
  color: #E06C75
  font-size: 4vw
  font-weight: 800

p
  color: #61AFEF
  font-size: 1.5vw
  font-weight: 200
